import React, { Fragment } from "react";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import { ToastProvider } from "react-toast-notifications";

import "./layout.css";

const Layout = ({ children }) => {
  return (
    <ToastProvider
      autoDismiss
      autoDismissTimeout={3000}
      placement="top-right"
    >
      <div className="container">
        <Fragment>
          <Header />
          <main className="main">{children}</main>
          <Footer />
        </Fragment>
      </div>
    </ToastProvider>
  );
};

export default Layout;

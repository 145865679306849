import React from "react";
import { Link } from "gatsby";

import "./footer.scss";

export const Footer = () => {
  return (
    <footer className="footer" id="footer">
      <ul className="list">
        <li>© {new Date().getFullYear()} All Rights Reserved</li>
        <li><Link to="/publicOffer">ПУБЛІЧНА ОФЕРТА</Link></li>
        <li><Link to="/cashBack">ПОВЕРНЕННЯ</Link></li>
        <li>м.Чернівці</li>
      </ul>
    </footer>
  );
};

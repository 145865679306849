import * as React from "react";
import { Link } from "gatsby";

import { routesName } from "../../shared/constants";

import * as styles from "./header.module.scss";

export const Header = () => (
  <header className={styles.header} id="header">
    <h1 className={styles.userName}>
      <Link to={routesName.home}>Анна Біла</Link>
    </h1>
    <ul className={styles.menu}>
      <li>
        <Link
          activeClassName={styles.menuLinkActive}
          className={styles.menuLink}
          to={routesName.proytySuprotyv}
        >
          Пройти супротив
        </Link>
      </li>
      <li>
        <Link
          activeClassName={styles.menuLinkActive}
          className={styles.menuLink}
          to={routesName.vpevnenist}
        >
          Впевненість
        </Link>
      </li>
    </ul>
  </header>
);
